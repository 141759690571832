import { Plane, Circle, useTexture, MeshReflectorMaterial } from '@react-three/drei'
import { useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import BackgroundPlane from './BackgroundPlane'

gsap.registerPlugin(ScrollTrigger)

function CarPlane() {
  const carTexture = useTexture('img/car_empty.png')
  const carDoorTexture = useTexture('img/car_door.png')
  const tireTexture = useTexture('img/wheel.png')

  console.log(document.querySelector('.hero-rig'))
  // console.log(document.querySelector('.overlay'))
  console.log(document.querySelector('.hero-rig-overlay'))
  console.log(document.querySelector('.interior'))
  const meshDoor = useRef()
  const meshGroup = useRef()
  const frontTireRef = useRef()
  const backTireRef = useRef()

  const isMobile = window.innerWidth < 768 // Adjust this breakpoint as needed

  const scaleConfig = isMobile ? { x: 0.37, y: 0.37, z: 0.37 } : { x: 0.9, y: 0.9, z: 0.9 }
  const scaleMultiplier = isMobile ? 1.85 : 1
  const positionConfig = isMobile ? { x: -1.74, y: 0.2 } : { x: -1.74, y: 0.2 }
  const meshPos = isMobile ? -0.8 : -0.2
  const wheelSpeed = isMobile ? 0.25 : 0.25

  useFrame(() => {
    frontTireRef.current.rotation.z -= wheelSpeed
    backTireRef.current.rotation.z -= wheelSpeed
  })

  useEffect(() => {
    meshGroup.current.scale.set(scaleConfig.x, scaleConfig.y, scaleConfig.z)
    meshGroup.current.position.set(0, meshPos, 0)

    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.refresh()
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero-rig-overlay',
        start: isMobile ? 'top top' : 'top top',
        end: isMobile ? '40% top' : '50% top',
        scrub: true,
        markers: false
      }
    })

    tl.fromTo(
      meshGroup.current.scale,
      { x: scaleConfig.x, y: scaleConfig.y, z: scaleConfig.z },
      { x: scaleConfig.x * 2 * scaleMultiplier, y: scaleConfig.y * 2 * scaleMultiplier, z: scaleConfig.z * 2 * scaleMultiplier }
    ).fromTo(meshDoor.current.position, { x: 0 }, { x: positionConfig.x, y: positionConfig.y }, 0)

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero-rig-overlay',
        start: isMobile ? '5% top' : '5% top',
        end: isMobile ? '40% top' : '50% top',
        scrub: true,
        markers: false
      }
    })

    tl2.fromTo('.hero-rig-overlay', { opacity: 0 }, { opacity: 1 })

    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero-rig-overlay',
        start: isMobile ? '-5% top' : '-5% top',
        end: isMobile ? '50% top' : '50% top',
        scrub: true,
        markers: false
      }
    })
    tl3.fromTo('.interior', { opacity: 0, scale: 0.75 }, { opacity: 1, delay: 1, scale: 1 }, '+=0')

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
    }
  }, [isMobile])

  return (
    <>
      <group ref={meshGroup}>
        <Plane args={[7.5, 3]} position={[0, 0.1, 2.5]}>
          <meshBasicMaterial depthWrite={false} transparent attach="material" map={carTexture} />
        </Plane>
        <Plane args={[7.5, 3]} position={[0, 0.105, 2.55]} ref={meshDoor}>
          <meshBasicMaterial depthWrite={false} transparent attach="material" map={carDoorTexture} />
        </Plane>
        <Circle args={[0.5, 32]} position={[-2.2, -0.84, 2.52]} ref={frontTireRef}>
          <meshBasicMaterial attach="material" map={tireTexture} />
        </Circle>
        <Circle args={[0.5, 32]} position={[2.81, -0.84, 2.53]} ref={backTireRef}>
          <meshBasicMaterial attach="material" map={tireTexture} />
        </Circle>
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1.35, 0]}>
          <planeGeometry args={[50, 50]} scale={100} />
          <MeshReflectorMaterial
            blur={[256, 256]}
            resolution={1024}
            mixBlur={1}
            mixStrength={30}
            roughness={0.7}
            depthScale={0.3}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#2d2d2d"
            metalness={0.5}
          />
        </mesh>
      </group>
    </>
  )
}

export default CarPlane
