import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/free-mode'
import './ImageGallery.css'

import { Autoplay, FreeMode } from 'swiper/modules' // Import modules from 'swiper/modules'

const importAllImages = () => {
  const images = []
  for (let i = 1; i <= 12; i++) {
    const imageNumber = i.toString().padStart(2, '0')
    images.push(require(`./img/content/image${imageNumber}.jpg`))
  }
  return images
}

const ImageGallery = () => {
  const [images, setImages] = useState([])
  const [contentLoaded, setContentLoaded] = useState(false)

  useEffect(() => {
    const loadedImages = importAllImages()
    setImages(loadedImages)

    // Simulate image load completion
    const imagePromises = loadedImages.map(
      (imageSrc) =>
        new Promise((resolve) => {
          const img = new Image()
          img.src = imageSrc
          img.onload = resolve
        })
    )
  }, [])

  return (
    <div className="sliding-gallery">
      <Swiper
        modules={[Autoplay, FreeMode]} // Include modules
        pagination={false}
        slidesPerView={2}
        spaceBetween={10}
        allowTouchMove={true} // Enable touch dragging
        loop={true}
        autoplay={{
          delay: 0
        }}
        speed={1500} // Adjust speed to 1.5 seconds (1500ms)
        breakpoints={{
          768: {
            slidesPerView: 3
          },
          480: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 24,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24
          }
        }}
        style={{ width: '100%' }}>
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Slide ${index + 1}`} className="image" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default ImageGallery
