import React, { lazy, Suspense, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Home from './pages/Home' // Home can remain eagerly loaded
import CustomCursor from 'custom-cursor-react'
import 'custom-cursor-react/dist/index.css'
import ScrollToTop from './utils/ScrolToTop'
import Preloader from './components/Preloader/Preloader'
import { gsap } from 'gsap'

// Lazy load the other pages
const About = lazy(() => import('./pages/About'))
const Gallery = lazy(() => import('./pages/Gallery'))
const Contact = lazy(() => import('./pages/Contact'))
const StarAlliances = lazy(() => import('./pages/StarAlliances'))

export default function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [progress, setProgress] = useState(0)

  const filesToLoad = [''] // Define your file URLs here

  useEffect(() => {
    const startTime = Date.now()
    let loaded = 0

    const initializeCanvas = async () => {
      return new Promise((resolve) => {
        const canvas = document.querySelector('.myCanvas canvas')
        if (!canvas) {
          console.error('Canvas element not found')
          resolve()
          return
        }

        const ctx = canvas.getContext('2d')
        if (!ctx) {
          console.error('2D context not available')
          resolve()
          return
        }

        // Simulate canvas drawing or loading
        setTimeout(() => {
          ctx.fillStyle = 'blue'
          ctx.fillRect(0, 0, canvas.width, canvas.height)
          resolve()
        }, 1000) // Simulate a delay for setup
      })
    }

    const loadFiles = async () => {
      for (const file of filesToLoad) {
        try {
          if (file.endsWith('.jpg') || file.endsWith('.png')) {
            const img = new Image()
            img.src = file
            await img.decode()
          } else if (file.endsWith('.json')) {
            await fetch(file).then((res) => res.json())
          } else {
            await fetch(file)
          }
          loaded += 1
          setProgress((loaded / (filesToLoad.length + 1)) * 100)
        } catch (err) {
          console.error(`Error loading file ${file}:`, err)
        }
      }

      await initializeCanvas()
      setProgress(100)
      const elapsedTime = Date.now() - startTime
      const remainingTime = Math.max(2000 - elapsedTime, 0)
      setTimeout(() => {
        gsap.to('.preloader', {
          duration: 1,
          opacity: 0,
          pointerEvents: 'none',
          onComplete: () => setIsLoading(false)
        })
      }, remainingTime)
    }

    loadFiles()
  }, [])

  if (isLoading) {
    return <Preloader progress={progress} />
  }

  return (
    <Router>
      <ScrollToTop />

      <CustomCursor
        targets={['a', 'button']}
        customClass="custom-cursor"
        dimensions={30}
        fill="#e6ff24"
        smoothness={{
          movement: 0.2,
          scale: 0.1,
          opacity: 0.2
        }}
        targetOpacity={0.2}
      />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/about"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <About />
            </Suspense>
          }
        />
        <Route
          path="/gallery"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Gallery />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="/star-alliances"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <StarAlliances />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  )
}
