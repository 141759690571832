import { useRef } from 'react'
import { Plane, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

function BackgroundPlane() {
  const meshRef = useRef()
  const texture = useTexture('img/skylineday.jpg') // Replace with the path to your image

  // Set texture wrapping and repeat properties
  texture.wrapS = THREE.RepeatWrapping // Enable repeating on the x-axis
  texture.repeat.set(1, 1) // Adjust how many times to repeat (5 times on x-axis, 1 on y-axis)

  useFrame(() => {
    // Move the texture to the left over time
    texture.offset.x += 0.0002 // Adjust the speed here

    // Reset the offset when it goes out of bounds (optional)
    if (texture.offset.x >= 1) {
      texture.offset.x = 0
    }
  })

  return (
    <Plane args={[25, 10]} position={[0, 1.2, 0]} ref={meshRef}>
      <meshBasicMaterial attach="material" map={texture} />
    </Plane>
  )
}

export default BackgroundPlane
