import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import './Preloader.css'

const Preloader = ({ progress }) => {
  useEffect(() => {
    gsap.to('.progress-bar', { width: `${progress}%`, duration: 0.5, ease: 'power2.out' })
  }, [progress])

  return (
    <div className="preloader">
      <div className="logo-container">
        <img src="img/Logo.png" alt="Logo" className="logo" />
      </div>
      <div className="progress-container">
        <div className="progress-bar"></div>
      </div>
    </div>
  )
}

export default Preloader
