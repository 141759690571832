import React, { useEffect, useState } from 'react'
import gsap from 'gsap'
import './Navbar.css'
import CustomButton from '../CustomButton'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        gsap.to('.navbar', {
          padding: '1rem 2rem',
          backgroundColor: 'rgba(44, 44, 44, 0.8)',
          backdropFilter: 'blur(4px)',
          duration: 0.3
        })
      } else {
        gsap.to('.navbar', {
          padding: '1rem 2rem',
          backgroundColor: 'transparent',
          backdropFilter: 'none',
          duration: 0.3
        })
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    animateMenu(!isMenuOpen)
  }

  const closeMenu = () => {
    if (window.innerWidth <= 768) {
      setIsMenuOpen(false)
      animateMenu(false)
    }
  }

  const animateMenu = (open) => {
    if (open) {
      gsap.to('.nav-links', { x: 0, opacity: 1, duration: 0.5, ease: 'power3.out' })
      gsap.fromTo('.nav-links li', { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 0.5, stagger: 0.1, ease: 'power3.out' })
      gsap.to('.burger span:nth-child(1)', { rotation: 45, y: 8, duration: 0.3 })
      gsap.to('.burger span:nth-child(2)', { opacity: 0, duration: 0.3 })
      gsap.to('.burger span:nth-child(3)', { rotation: -45, y: -8, duration: 0.3 })
    } else {
      gsap.to('.nav-links', { x: '100%', opacity: 0, duration: 0.5, ease: 'power3.in' })
      gsap.to('.burger span:nth-child(1)', { rotation: 0, y: 0, duration: 0.3 })
      gsap.to('.burger span:nth-child(2)', { opacity: 1, duration: 0.3 })
      gsap.to('.burger span:nth-child(3)', { rotation: 0, y: 0, duration: 0.3 })
    }
  }

  return (
    <nav className="navbar">
      <div className="logo">
        <a href="/" onClick={closeMenu}>
          <img src="img/Logo.png" alt="Logo" />
        </a>
      </div>
      <div className="mobile-wrapper-nav">
        <div className="mobileshow">
          <a href="https://instagram.com/milesnmicpodcast" onClick={closeMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_277_44)">
                <path
                  d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M16.5 3H7.5C5.01472 3 3 5.01472 3 7.5V16.5C3 18.9853 5.01472 21 7.5 21H16.5C18.9853 21 21 18.9853 21 16.5V7.5C21 5.01472 18.9853 3 16.5 3Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_277_44">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>

          <a href="https://www.tiktok.com/@milesnmic?_t=8rUUdycQMza&_r=1" onClick={closeMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_277_49)">
                <path
                  d="M15.75 9.5625C17.279 10.6631 19.1161 11.2536 21 11.25V7.5C19.6076 7.5 18.2723 6.94688 17.2877 5.96231C16.3031 4.97774 15.75 3.64239 15.75 2.25H12V14.625C11.9998 15.0947 11.8736 15.5558 11.6345 15.9601C11.3954 16.3644 11.0522 16.6971 10.6407 16.9236C10.2291 17.1501 9.76439 17.262 9.2949 17.2477C8.8254 17.2333 8.36834 17.0933 7.97141 16.8421C7.57448 16.591 7.25221 16.2379 7.03822 15.8198C6.82423 15.4016 6.72636 14.9337 6.75482 14.4649C6.78328 13.996 6.93702 13.5434 7.20001 13.1542C7.463 12.765 7.82561 12.4535 8.25 12.2522V8.25C5.26594 8.78156 3 11.4881 3 14.625C3 16.3158 3.67165 17.9373 4.86719 19.1328C6.06274 20.3284 7.68424 21 9.375 21C11.0658 21 12.6873 20.3284 13.8828 19.1328C15.0784 17.9373 15.75 16.3158 15.75 14.625V9.5625Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_277_49">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div className="burger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <ul className="nav-links">
        <li>
          <a href="/" onClick={closeMenu}>
            Home
          </a>
        </li>
        <li>
          <a href="/about" onClick={closeMenu}>
            About
          </a>
        </li>
        <li>
          <a href="/gallery" onClick={closeMenu}>
            Gallery
          </a>
        </li>
        <li>
          <a href="/star-alliances" onClick={closeMenu}>
            Star Alliances
          </a>
        </li>
        <li className="hideevenonmobile">
          <a href="https://instagram.com/milesnmicpodcast" onClick={closeMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_277_44)">
                <path
                  d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M16.5 3H7.5C5.01472 3 3 5.01472 3 7.5V16.5C3 18.9853 5.01472 21 7.5 21H16.5C18.9853 21 21 18.9853 21 16.5V7.5C21 5.01472 18.9853 3 16.5 3Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_277_44">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </li>
        <li className="hideevenonmobile">
          <a href="https://www.tiktok.com/@milesnmic?_t=8rUUdycQMza&_r=1" onClick={closeMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_277_49)">
                <path
                  d="M15.75 9.5625C17.279 10.6631 19.1161 11.2536 21 11.25V7.5C19.6076 7.5 18.2723 6.94688 17.2877 5.96231C16.3031 4.97774 15.75 3.64239 15.75 2.25H12V14.625C11.9998 15.0947 11.8736 15.5558 11.6345 15.9601C11.3954 16.3644 11.0522 16.6971 10.6407 16.9236C10.2291 17.1501 9.76439 17.262 9.2949 17.2477C8.8254 17.2333 8.36834 17.0933 7.97141 16.8421C7.57448 16.591 7.25221 16.2379 7.03822 15.8198C6.82423 15.4016 6.72636 14.9337 6.75482 14.4649C6.78328 13.996 6.93702 13.5434 7.20001 13.1542C7.463 12.765 7.82561 12.4535 8.25 12.2522V8.25C5.26594 8.78156 3 11.4881 3 14.625C3 16.3158 3.67165 17.9373 4.86719 19.1328C6.06274 20.3284 7.68424 21 9.375 21C11.0658 21 12.6873 20.3284 13.8828 19.1328C15.0784 17.9373 15.75 16.3158 15.75 14.625V9.5625Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_277_49">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </li>
        <li className="navButton">
          <CustomButton urlSegment="/v2/#book/count/1/" className="fixedbook"></CustomButton>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
