import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import BackgroundPlane from '../components/BackgroundPlane'
import CarPlane from '../components/CarPlane'
import { NeonRectangle } from '../components/NeonRectangle'
import ImageWithHotspots from '../components/ImageWithHotspots/ImageWithHotspot'
import gsap from 'gsap'
import ImageGallery from '../components/ImageGallery/ImageGallery'

// Lazy-load these components
const SecondContainer = lazy(() => import('../components/SecondContainer'))
const PodcastPromo = lazy(() => import('../components/PodcastPromo/PodcastPromo'))
// const ImageGallery = lazy(() => import('../components/ImageGallery/ImageGallery'))
const FAQ = lazy(() => import('../components/FAQ'))
// const SimplyBookWidget = lazy(() => import('../components/SimplyBookWidget'));

const Home = () => {
  const [isMobile, setIsMobile] = useState(false)
  const headlineRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    if (!headlineRef.current) return

    const lines = headlineRef.current.querySelectorAll('h1, h2, p')
    gsap.from(lines, {
      opacity: 0,
      y: 50,
      stagger: 0.3,
      duration: 1
    })

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)

      if (canvasRef.current) {
        const canvasElement = canvasRef.current
        canvasElement.style.width = '100%'
        canvasElement.style.height = '100%'
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const hotspots = isMobile
    ? [
        { id: 1, top: '52%', left: '40%', label: 'DJI OSMO POCKET 3 CAMERAS...' },
        { id: 2, top: '85%', left: '75%', label: 'RODE PODMIC PODCAST MICROPHONES...' },
        { id: 3, top: '88%', left: '55%', label: 'RODE RODECASTER PRO...' },
        { id: 4, top: '93%', left: '42%', label: 'BLACKMAGIC DESIGN ATEM MINI PRO...' },
        { id: 5, top: '125%', left: '55%', label: 'NESPRESSO Machine' },
        { id: 6, top: '32%', left: '22%', label: 'Headlight' },
        { id: 7, top: '37%', left: '56%', label: 'Frontlight' }
      ]
    : [
        { id: 1, top: '12%', left: '44%', label: 'DJI OSMO POCKET 3 CAMERAS...' },
        { id: 2, top: '30%', left: '70%', label: 'RODE PODMIC PODCAST MICROPHONES...' },
        { id: 3, top: '33%', left: '52%', label: 'RODE RODECASTER PRO...' },
        { id: 4, top: '37%', left: '42%', label: 'BLACKMAGIC DESIGN ATEM MINI PRO...' },
        { id: 5, top: '45%', left: '52%', label: 'NESPRESSO Machine' },
        { id: 6, top: '3%', left: '30%', label: 'Headlight' },
        { id: 7, top: '7%', left: '60%', label: 'Frontlight' }
      ]

  const imageSrc = isMobile ? 'img/169.jpg' : 'img/1691.jpg'

  return (
    <div>
      <div className="hero-rig">
        <div className="hero-headline" ref={headlineRef}>
          <h1>UAE's First</h1>
          <h2>Mobile Podcast Studio</h2>
          <p>Dubai's first Podcast Studio, that comes to you.</p>
        </div>
        <div className="hero-rig-overlay"></div>
        <div className="interior">
          <ImageWithHotspots src={imageSrc} width="100%" height="100%" hotspots={hotspots} />
        </div>
        <Canvas
          ref={canvasRef}
          className="myCanvas"
          shadows
          camera={{ position: [0, 1.5, 9], fov: 50 }}
          onCreated={() => {
            window.dispatchEvent(new Event('resize'))
          }}>
          <ambientLight intensity={1} />
          <group>
            <NeonRectangle />
            <BackgroundPlane />
            <CarPlane />
          </group>
        </Canvas>
      </div>

      {/* Lazy-loaded sections */}
      <Suspense fallback={<div>Loading Second Section...</div>}>
        <SecondContainer />
      </Suspense>
      <Suspense fallback={<div>Loading Podcast Promo...</div>}>
        <PodcastPromo />
      </Suspense>
      <ImageGallery />

      <Suspense fallback={<div>Loading FAQ...</div>}>
        <FAQ />
      </Suspense>
      {/* <Suspense fallback={<div>Loading Booking Widget...</div>}>
        <SimplyBookWidget />
      </Suspense> */}
    </div>
  )
}

export default Home
