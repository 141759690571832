import React, { useState, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import './ImageWithHotspots.css'

const ImageWithHotspots = ({ src, width, height, hotspots }) => {
  const [tooltip, setTooltip] = useState(null)
  const hotspotRefs = useRef([])
  const tooltipRef = useRef(null)

  const handleHotspotClick = (hotspot) => {
    if (tooltip === hotspot) {
      animateTooltipOut()
      setTimeout(() => setTooltip(null), 300) // Wait for the out animation to complete
    } else {
      setTooltip(hotspot)
    }
  }

  const animateTooltipIn = () => {
    gsap.fromTo(tooltipRef.current, { opacity: 0, scale: 0.8, y: 20 }, { opacity: 1, scale: 1, y: 25, duration: 0.3, ease: 'power2.out' })
  }

  const animateTooltipOut = () => {
    gsap.to(tooltipRef.current, {
      opacity: 0,
      scale: 0.8,
      y: 0,
      duration: 0.3,
      ease: 'power2.in'
    })
  }

  useEffect(() => {
    // Animate each hotspot to create a pulsating effect
    hotspotRefs.current.forEach((hotspot) => {
      gsap.to(hotspot, {
        scale: 1.2,
        duration: 1,
        yoyo: true,
        repeat: -1,
        ease: 'power1.inOut'
      })
    })
  }, [])

  useEffect(() => {
    if (tooltip && tooltipRef.current) {
      animateTooltipIn()
    }
  }, [tooltip])

  return (
    <div className="image-with-hotspots" style={{ width, height }}>
      <img src={src} alt="Image with hotspots" />

      {hotspots.map((hotspot, index) => (
        <div
          key={hotspot.id}
          ref={(el) => (hotspotRefs.current[index] = el)} // Store ref for each hotspot
          className="hotspot"
          style={{ top: hotspot.top, left: hotspot.left }}
          onClick={() => handleHotspotClick(hotspot)}>
          <div className="inner-circle" />
        </div>
      ))}

      {tooltip && (
        <div ref={tooltipRef} className="tooltip" style={{ top: tooltip.top, left: tooltip.left }}>
          <div className="tooltip-content">{tooltip.label}</div>
        </div>
      )}
    </div>
  )
}

export default ImageWithHotspots
